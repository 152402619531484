import * as React from 'react'
import { Link } from 'gatsby'

import IndexLayout from '../layouts'
import styled from '@emotion/styled'

const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 17vh 20px 0 20px;
  a {
    color: #f7614a;
  }
`

const NotFoundPage = () => (
  <IndexLayout>
    <Wrapper>
      <h1>404: Page not found.</h1>
      <p>
        You've hit the void. <Link to="/">Go back.</Link>
      </p>
    </Wrapper>
  </IndexLayout>
)

export default NotFoundPage
